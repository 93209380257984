// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-trust-js": () => import("./../../../src/pages/about-trust.js" /* webpackChunkName: "component---src-pages-about-trust-js" */),
  "component---src-pages-academic-calendar-js": () => import("./../../../src/pages/academic-calendar.js" /* webpackChunkName: "component---src-pages-academic-calendar-js" */),
  "component---src-pages-affidavits-js": () => import("./../../../src/pages/affidavits.js" /* webpackChunkName: "component---src-pages-affidavits-js" */),
  "component---src-pages-annual-reports-js": () => import("./../../../src/pages/annual-reports.js" /* webpackChunkName: "component---src-pages-annual-reports-js" */),
  "component---src-pages-blogs-js": () => import("./../../../src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-books-list-js": () => import("./../../../src/pages/books-list.js" /* webpackChunkName: "component---src-pages-books-list-js" */),
  "component---src-pages-campus-area-and-classrooms-js": () => import("./../../../src/pages/campus-area-and-classrooms.js" /* webpackChunkName: "component---src-pages-campus-area-and-classrooms-js" */),
  "component---src-pages-chairmans-message-js": () => import("./../../../src/pages/chairmans-message.js" /* webpackChunkName: "component---src-pages-chairmans-message-js" */),
  "component---src-pages-clubs-and-committees-js": () => import("./../../../src/pages/clubs-and-committees.js" /* webpackChunkName: "component---src-pages-clubs-and-committees-js" */),
  "component---src-pages-details-of-non-teaching-staff-js": () => import("./../../../src/pages/details-of-non-teaching-staff.js" /* webpackChunkName: "component---src-pages-details-of-non-teaching-staff-js" */),
  "component---src-pages-details-of-teachers-js": () => import("./../../../src/pages/details-of-teachers.js" /* webpackChunkName: "component---src-pages-details-of-teachers-js" */),
  "component---src-pages-directors-message-js": () => import("./../../../src/pages/directors-message.js" /* webpackChunkName: "component---src-pages-directors-message-js" */),
  "component---src-pages-fee-structure-js": () => import("./../../../src/pages/fee-structure.js" /* webpackChunkName: "component---src-pages-fee-structure-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-list-of-holidays-js": () => import("./../../../src/pages/list-of-holidays.js" /* webpackChunkName: "component---src-pages-list-of-holidays-js" */),
  "component---src-pages-mail-us-js": () => import("./../../../src/pages/mail-us.js" /* webpackChunkName: "component---src-pages-mail-us-js" */),
  "component---src-pages-mandatory-public-disclosures-js": () => import("./../../../src/pages/mandatory-public-disclosures.js" /* webpackChunkName: "component---src-pages-mandatory-public-disclosures-js" */),
  "component---src-pages-media-gallery-js": () => import("./../../../src/pages/media-gallery.js" /* webpackChunkName: "component---src-pages-media-gallery-js" */),
  "component---src-pages-no-homework-policy-js": () => import("./../../../src/pages/no-homework-policy.js" /* webpackChunkName: "component---src-pages-no-homework-policy-js" */),
  "component---src-pages-number-of-students-js": () => import("./../../../src/pages/number-of-students.js" /* webpackChunkName: "component---src-pages-number-of-students-js" */),
  "component---src-pages-our-mission-js": () => import("./../../../src/pages/our-mission.js" /* webpackChunkName: "component---src-pages-our-mission-js" */),
  "component---src-pages-our-motto-js": () => import("./../../../src/pages/our-motto.js" /* webpackChunkName: "component---src-pages-our-motto-js" */),
  "component---src-pages-our-vision-js": () => import("./../../../src/pages/our-vision.js" /* webpackChunkName: "component---src-pages-our-vision-js" */),
  "component---src-pages-page-2-tsx": () => import("./../../../src/pages/page-2.tsx" /* webpackChunkName: "component---src-pages-page-2-tsx" */),
  "component---src-pages-photo-gallery-js": () => import("./../../../src/pages/photo-gallery.js" /* webpackChunkName: "component---src-pages-photo-gallery-js" */),
  "component---src-pages-principals-message-js": () => import("./../../../src/pages/principals-message.js" /* webpackChunkName: "component---src-pages-principals-message-js" */),
  "component---src-pages-reach-us-js": () => import("./../../../src/pages/reach-us.js" /* webpackChunkName: "component---src-pages-reach-us-js" */),
  "component---src-pages-school-circulars-js": () => import("./../../../src/pages/school-circulars.js" /* webpackChunkName: "component---src-pages-school-circulars-js" */),
  "component---src-pages-student-council-js": () => import("./../../../src/pages/student-council.js" /* webpackChunkName: "component---src-pages-student-council-js" */),
  "component---src-pages-transfer-certificates-js": () => import("./../../../src/pages/transfer-certificates.js" /* webpackChunkName: "component---src-pages-transfer-certificates-js" */)
}

